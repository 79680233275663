import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface Property {
  key: string;
  value: string;
  isFooter: boolean;
}

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.css']
})
export class EditDialogComponent implements OnInit, OnDestroy {

  name = '';
  value: string;
  type = 'input';
  activeTab: 'table' | 'text' = 'table';
  emptyProperty: Property = {
    key: '',
    value: '',
    isFooter: false
  };
  parsedProperties: Property[] = [];
  @ViewChild('tableRef', {static: false}) tableRef: ElementRef;

  constructor(public dialogRef: MatDialogRef<EditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { name: string, type: any, value: string },
              private changeDetector: ChangeDetectorRef) {
    this.name = data.name;
    this.value = data.value;
    this.type = data.type;
  }

  ngOnInit(): void {
    this.parsedProperties = this.parseProperties();
    this.parsedProperties.push({key: '', value: '', isFooter: true});
  }

  // FIX THIS LATER
  ngAfterViewInit() {
    document.addEventListener('keydown', this.saveShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.saveShortcutListener, false);
  }

  saveShortcutListener = (e) => {
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode == 83) {
      e.preventDefault();
      document.getElementById('okButton').click();
    }
  };

  enterKeyListener = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let inputs = Array.from(document.querySelectorAll('mat-table input'));
      const currentIndex = inputs.indexOf(e.target as Element);

      if (currentIndex === inputs.length - 1) {
        this.handleFooterInput();
      }
    }
  };

  handleFooterInput(): void {
    const footerElement = this.parsedProperties[this.parsedProperties.length - 1];

    if (footerElement.key && footerElement.value) {
      footerElement.isFooter = false;

      // Update parsedProperties in an immutable way
      this.parsedProperties = [...this.parsedProperties, {...this.emptyProperty, isFooter: true}];

      this.changeDetector.detectChanges();

      setTimeout(() => {
        let tableRef = this.tableRef.nativeElement;
        if (tableRef) {
          const inputs = Array.from(tableRef.querySelectorAll('input'));
          if (inputs.length > 0) {
            const newFooterKeyInput = inputs[inputs.length - 1];
            (newFooterKeyInput as HTMLInputElement).focus();
          }
        }
      }, 100);
    }
  }


  deleteRow(index: number) {
    this.parsedProperties = [
      ...this.parsedProperties.slice(0, index),
      ...this.parsedProperties.slice(index + 1)
    ];
    this.updateValueFromProperties();
  }

  updateValueFromProperties(): void {
    if (this.activeTab === 'table') {
      this.value = this.parsedProperties
        .filter(prop => prop.key !== '' || prop.value !== '')
        .map(prop => `${prop.key}=${prop.value}`)
        .join('\n');
    }
    // For the text view, `value` is already bound to the textarea and will include empty lines,
    // so no additional logic is needed here for saving.
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  private parseProperties(): Property[] {
    return this.value.split('\n')
      .map(line => line.trim())
      .filter(line => line)
      .map(line => {
        const [key, value = ''] = line.split('=').map(part => part.trim());
        return {key, value, isFooter: false};
      });
  }
}
