<div class="data-wrapper">
  <!--    <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>-->

  <!--            <div class="data-title">-->
  <!--              Your data-->
  <!--            </div>-->

  <!--            <mat-card [ngClass]="{'shadow': shadow, 'shadow-none': !shadow}">-->
  <div class="data">
    <label class="data_label">Email: </label>
    <span class="data_span">{{userProject.user.email}}</span>
  </div>

  <div class="data">
    <label class="data_label">Display Name: </label>
    <span class="data_span">
          {{userProject.user.displayName}}
      <button (click)="openUserEditFieldDialog('input','Display Name:','displayName')" *ngIf="allowEditName"
              mat-icon-button>
            <mx-icon class="edit-icon" color="accent" hoverColor="primary" mx="edit"></mx-icon>
          </button>
        </span>
  </div>

  <div *ngIf="userProjectDetailsShow" class="data">

    <label class="data_label">SecurityGroups: </label>
    <div *ngIf="allowEditSecurityGroups" class="data_span">
      <mat-select (valueChange)="changeSecurityGroup($event)" *ngIf="securityGroups != null && securityGroups.length > 0"
                  [disabled]="!checkPaths.updateRoles.enabled "
                  [formControl]="selectedSecurityGroups" multiple>
        <mat-option *ngFor="let securityGroup of securityGroups"
                    [disabled]="securityGroup.name == 'OWNER' && this.ownerDisabled"
                    [value]="securityGroup">{{securityGroup.publicName}}</mat-option>
      </mat-select>
    </div>

    <div *ngIf="!allowEditSecurityGroups" class="data_span">
      {{ showSecurityGroups() }}
    </div>
  </div>

  <div *ngIf="editPassword" class="data">
    <label class="data_label">Password: </label>
    <span class="data_span">
          Change password
          <button (click)="editPasswordDialogComponent()" mat-icon-button>
            <mx-icon class="edit-icon" color="accent" hoverColor="primary" mx="edit"></mx-icon>
          </button>
        </span>
  </div>

  <div *ngIf="showApiKey" class="data">
    <label class="data_label">
      Api key:


      <button (click)="apiKeyInfo()" color="primary" mat-icon-button>
        <mx-icon mx="information"></mx-icon>

      </button>

    </label>
    <span *ngIf="showApiKey" class="data_span">
          <button (click)="createApiKeyDialogComponent()" *ngIf="!this.userProject.existApiKey" color="primary"
                  mat-raised-button>
            Create api key
          </button>
          <div *ngIf="this.userProject.existApiKey" style="    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;">
            <div class="mr-1">
            mx-*****************
          </div>
          <button (click)="deleteApiKeyDialogComponent()" class="ml-1" color="warn" mat-raised-button>
            Invalidate this api key
          </button>

          </div>



        </span>
  </div>

  <div *ngIf="userProjectDetailsShow && bridgeActive" class="data">
    <label class="data_label">Bridge: </label>
    <span class="data_span" style="overflow: visible">

           <button [matTooltip]="bridgeActive? 'active' : 'inactive'"
                   [ngClass]="{
                      'stopped-color': !bridgeActive,
                      'running-color': bridgeActive
                  }"
                   class="sensor-btn" mat-mini-fab>
          </button>
        </span>
  </div>

  <!--            </mat-card>-->
</div>
